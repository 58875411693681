



















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { Route } from 'vue-router'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine
  }
})
export default class PreTest extends Vue {
  private advancement = 0
  private hideStepCount = true

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (newRoute: Route): void {
    this.advancement = newRoute?.meta?.advancement
    this.hideStepCount = newRoute?.meta?.hideStepCount || false
  }

  private destroyed (): void {
    if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
  }
}
