









import { Component, Prop, Vue } from 'vue-property-decorator'
import router from '@/router'

@Component({})
export default class PrimaryButton extends Vue {
  @Prop({ type: Object as () => { name?: string }, required: false, default: () => ({}) })
  public destinationRoute!: { name?: string }

  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  private handleClick (): void {
    if (this.disabled) {
      return
    }

    if (this.$listeners.click) {
      this.$emit('click')
    } else {
      router.push(this.destinationRoute)
    }
  }
}
