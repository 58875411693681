







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Title extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  public text!: string
}
